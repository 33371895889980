import classes from './title.module.sass'

interface IProps {
  label: string
}

export const Title: React.FC<IProps> = ({label}) => {
  return (
    <h2 className={classes.title}>{label}</h2>
  )
}