import { NavLink } from "react-router-dom";
import { Button } from "components/common/button/button.component";
import { IconType } from "constants/icons";
import { useGetCoil } from "hooks/useGetCoil";
import LogoTC from "assets/imgs/logo_topcable.svg";
import classes from "./footer.module.sass";
import { useMemo } from "react";

export const Footer: React.FC = () => {
  const { selectedCoil } = useGetCoil();
  const disabledBtns = useMemo(() => {
    if (!selectedCoil) {
      return true;
    }
    if (selectedCoil.finishedCoil) {
      return true;
    }
    return false;
  }, [selectedCoil]);
  return (
    <div className={classes.wrapper}>
      <div className={classes.poweredBy}>
        <span>Powered by</span>
        <a href="https://topcable.com" rel="nofollow" target={"_blank"}>
          <img src={LogoTC} alt="" />
        </a>{" "}
        <small className={classes.versionBadge}>v.1.0.2</small>
      </div>
      <div className={classes["menu-container"]}>
        <NavLink
          to={disabledBtns ? "#" : `/${selectedCoil?.deviceNumber}`}
          className={({ isActive }) =>
            isActive && !disabledBtns ? classes.active : undefined
          }
        >
          <Button
            label="Home"
            type="light"
            hideText={true}
            iconType={IconType.Home}
            size="sm"
            disabled={disabledBtns}
          />
        </NavLink>
        <NavLink
          to={
            disabledBtns ? "#" : `/consummation/${selectedCoil?.deviceNumber}`
          }
          className={({ isActive }) =>
            isActive && !disabledBtns ? classes.active : undefined
          }
        >
          <Button
            label="Terminate"
            type="light"
            hideText={true}
            iconType={IconType.Rule}
            size="sm"
            disabled={disabledBtns}
          />
        </NavLink>
        <NavLink
          to={disabledBtns ? "#" : `/terminate/${selectedCoil?.deviceNumber}`}
          className={({ isActive }) =>
            isActive && !disabledBtns ? classes.active : undefined
          }
        >
          <Button
            label="Terminate"
            type="light"
            hideText={true}
            iconType={IconType.Check}
            size="sm"
            disabled={disabledBtns}
          />
        </NavLink>
      </div>
    </div>
  );
};
