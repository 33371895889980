import { ReactComponent as Arrow } from "assets/imgs/arrow-icon.svg";
import { ReactComponent as CaretDown } from "assets/imgs/caret-down-icon.svg";
import { ReactComponent as CaretUp } from "assets/imgs/cartet-up-icon.svg";
import { ReactComponent as Check } from "assets/imgs/check-icon.svg";
import { ReactComponent as Home } from "assets/imgs/home-icon.svg";
import { ReactComponent as LogoDTTS } from "assets/imgs/logo_dtts.svg";
import { ReactComponent as LogoTopCable } from "assets/imgs/logo_topcable.svg";
import { ReactComponent as Pencil } from "assets/imgs/pencil-icon.svg";
import { ReactComponent as Rule } from "assets/imgs/rule-icon.svg";
import { ReactComponent as Tick } from "assets/imgs/tick-icon.svg";

export enum IconType {
  Arrow = "Arrow",
  CaretDown = "CaretDown",
  CaretUp = "CaretUp",
  Check = "Check",
  Home = "Home",
  LogoDTTS = "LogoDTTS",
  LogoTopCable = "LogoTopCable",
  Pencil = "Pencil",
  Rule = "Rule",
  Tick = "Tick",
}

export const Icon = {
  [IconType.Arrow]: <Arrow />,
  [IconType.CaretDown]: <CaretDown />,
  [IconType.CaretUp]: <CaretUp />,
  [IconType.Check]: <Check />,
  [IconType.Home]: <Home />,
  [IconType.LogoDTTS]: <LogoDTTS />,
  [IconType.LogoTopCable]: <LogoTopCable />,
  [IconType.Pencil]: <Pencil />,
  [IconType.Rule]: <Rule />,
  [IconType.Tick]: <Tick />,
};
