import classes from "./itemInfo.module.sass";

interface IProps {
  label: string;
  data: string;
  disabled?: boolean;
}

export const ItemInfo: React.FC<IProps> = ({ label, data, disabled }) => {
  return (
    <div className={`${classes.wrapper} text-center`}>
      <p>
        <span className={classes.label}>{label}</span>
        <span className={`${classes.data} ${disabled && classes.disabled}`}>
          {data}
        </span>
      </p>
    </div>
  );
};
