import { Icon, IconType } from "constants/icons";
import classes from "./button.module.sass";

interface IProps {
  label: string;
  type: "dark" | "light";
  size?: "sm" | "md" | "back-btn";
  hideText?: boolean;
  iconType?: IconType;
  shadow?: boolean;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<IProps> = ({
  label,
  type,
  hideText = false,
  iconType,
  size,
  shadow = false,
  disabled = false,
  onClick,
}) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      aria-labelledby="btn_text"
      className={`${classes.wrapper} ${classes[type]} ${size && classes[size]
        } ${shadow && classes.shadow} ${disabled && classes.disabled}`}
    >
      {iconType && Icon[iconType]}
      <span id="btn_text" hidden={hideText} className={classes.label}>
        {label}
      </span>
    </div>
  );
};
