import {
  useEffect,
  useMemo,
  useState,
  useCallback,
  SyntheticEvent,
} from "react";
import dayjs from "dayjs";
import { Button } from "components/common/button/button.component";
import { Title } from "components/common/title/title.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { IconType } from "constants/icons";
import { Link } from "react-router-dom";
import { useGetCoil } from "hooks/useGetCoil";
import { useGetCoilId } from "hooks/useGetCoilId";
import { useParams, useNavigate } from "react-router-dom";
import { Input } from "components/common/input/input.component";
import { ErrorMessage } from "components/common/errorMessage/errorMessage.component";
import IconBatteryFull from "../../../assets/imgs/battery_full_bg_white.svg";
import IconBatteryHalf from "../../../assets/imgs/battery_half_bg_white.svg";
import IconBatteryEmpty from "../../../assets/imgs/battery_empty_bg_white.svg";

import classes from "./detail.module.sass";

export const Detail = () => {
  const params = useParams();
  const navigate = useNavigate();

  const [enteredCoil, setEnteredCoil] = useState("");
  const {
    getCoil,
    selectedCoil,
    isLoading: isLoadingGetCoil,
    error: errorGetCoil,
  } = useGetCoil();
  const {
    getCoilId,
    isLoading: isLoadingGetCoilId,
    error: errorGetCoilId,
  } = useGetCoilId();

  const isLoading = useMemo(() => {
    return isLoadingGetCoil || isLoadingGetCoilId;
  }, [isLoadingGetCoil, isLoadingGetCoilId]);

  const error = useMemo(() => {
    return errorGetCoil || errorGetCoilId;
  }, [errorGetCoil, errorGetCoilId]);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const urlParams = Object.fromEntries(urlSearchParams.entries());

    if (urlParams.coil) {
      navigate(`/${urlParams.coil}`);
      return;
    }

    if (params.deviceNumber) {
      getCoil(params.deviceNumber);
      return;
    }

    if (selectedCoil) {
      getCoil(selectedCoil.deviceNumber);
      return;
    }
  }, [params.deviceNumber]);

  const formattedDepartureDate = useMemo(() => {
    if (!selectedCoil) {
      return;
    }
    const d = dayjs(selectedCoil.dateOfDeparture);
    return d.format("DD-MM-YYYY h:mm A");
  }, [selectedCoil]);

  const disableLinks = useMemo(() => {
    if (!selectedCoil) {
      return true;
    }

    if (selectedCoil.finishedCoil) {
      return true;
    }

    return false;
  }, [selectedCoil]);

  const handleOnClickSend = useCallback(async () => {
    const coilId = await getCoilId(enteredCoil);
    console.log(coilId);
    if (!coilId) {
      return;
    }
    getCoil(coilId.id);
  }, [enteredCoil]);

  const handleOnSubmitSelectBobine = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault();
      handleOnClickSend();
    },
    [enteredCoil]
  );

  const handleOnChangeEnteredCoil = (value: string) => {
    setEnteredCoil(value);
  };

  const batteryInfo = useMemo(() => {
    if (!selectedCoil) return;
    const { batteryLevel } = selectedCoil;
    if (typeof batteryLevel === "string") return "-";
    let batteryIcon = IconBatteryFull;
    if (batteryLevel >= 0 && batteryLevel < 50) {
      batteryIcon = IconBatteryEmpty;
    } else if (batteryLevel >= 50 && batteryLevel < 60) {
      batteryIcon = IconBatteryHalf;
    } else {
      batteryIcon = IconBatteryFull;
    }
    return (
      <span className={classes.itemInfo}>
        {batteryLevel}%{" "}
        <img src={batteryIcon} alt="" className={classes.batteryIcon} />
      </span>
    );
  }, [selectedCoil]);

  return (
    <>
      <Title
        label={
          selectedCoil ? "Informations sur la bobine" : "Sélectionnez la bobine"
        }
      />
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      {!isLoading && selectedCoil && (
        <>
          <div className={classes.infoWrapper}>
            <h3 className={classes.listTitle}>{selectedCoil.id}</h3>
            <ul>
              <li>
                <span className={classes.itemHeader}>Code produit: </span>
                <span className={classes.itemInfo}>
                  {selectedCoil.codeProduct}
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>
                  Numéro Du Bon De Livraison:{" "}
                </span>
                <span className={classes.itemInfo}>
                  {selectedCoil.deliveryNoteNumber}
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>Client: </span>
                <span className={classes.itemInfo}>{selectedCoil.client}</span>
              </li>
              <li>
                <span className={classes.itemHeader}>Date de Départ: </span>
                <span className={classes.itemInfo}>
                  {formattedDepartureDate}
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>Mètres initiaux: </span>
                <span className={classes.itemInfo}>
                  {selectedCoil.initialMeters}m
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>Mètres courants: </span>
                <span className={`${classes.itemInfo} text-main`}>
                  {selectedCoil.runningMeters}m
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>Bobine finie: </span>
                <span className={`${classes.itemInfo} text-main`}>
                  {selectedCoil.finishedCoil === 0 ? "No" : "Oui"}
                </span>
              </li>
              <li>
                <span className={classes.itemHeader}>Niveau de batterie: </span>
                {batteryInfo}
              </li>
            </ul>
          </div>
          <div className={classes.btnsWrapper}>
            <Link
              to={
                disableLinks
                  ? "#"
                  : `/consummation/${selectedCoil?.deviceNumber}`
              }
            >
              <Button
                label="Ajouter Consommation"
                type="dark"
                hideText={false}
                iconType={IconType.Rule}
                disabled={disableLinks}
                size="md"
                shadow={true}
              />
            </Link>
            <Link
              to={
                disableLinks ? "#" : `/terminate/${selectedCoil?.deviceNumber}`
              }
            >
              <Button
                label="Terminer"
                type="dark"
                hideText={false}
                iconType={IconType.Check}
                size="md"
                disabled={disableLinks}
                shadow={true}
              />
            </Link>
          </div>
        </>
      )}
      {!selectedCoil && (
        <>
          <form onSubmit={handleOnSubmitSelectBobine}>
            <Input
              type="text"
              onChange={handleOnChangeEnteredCoil}
              placeholder="Ex: 207DF7"
              style={{ marginBottom: 20 }}
            />
            <Button label="Envoyer" type="dark" onClick={handleOnClickSend} />
          </form>
        </>
      )}
    </>
  );
};
