import { useState } from "react";
import EP from "constants/EP";

const useCoilStatus = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [coilStatus, setCoilStatus] = useState<number | null>(null);
  const [error, setError] = useState<null | string>(null);

  const updateCoilStatus = async (newCoilStatus: {
    id: string;
    finishedCoil: number;
  }) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${EP}coil-status`, {
        cache: "no-cache",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCoilStatus),
      });
      const consumption = await response.json();
      if (consumption.error) {
        throw new Error(consumption.error);
      }
      setCoilStatus(consumption.coilStatus);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { updateCoilStatus, coilStatus, error, isLoading };
};

export { useCoilStatus };
