import { BaseSyntheticEvent, useCallback, useEffect, useState } from "react";
import caretUp from "assets/imgs/cartet-up-icon.svg";
import caretDown from "assets/imgs/caret-down-icon.svg";

import classes from "./metersConsumer.module.sass";

interface IProps {
  initialValue?: number;
  max?: number;
  reset?: boolean;
  onChange?: (value: number) => void;
}

export const MetersConsumer: React.FC<IProps> = ({
  onChange,
  initialValue = 0,
  reset,
  max,
}) => {
  const [value, setValue] = useState<number>(initialValue);

  const limitValue = (value: number, max?: number) => {
    return value <= 0
      ? 0
      : typeof max === "number"
      ? value >= max
        ? max
        : value
      : value;
  };

  const handleOnChange = useCallback(
    (e: BaseSyntheticEvent) => {
      // return setValue(Number(e.target.value));
      setValue(limitValue(Number(e.target.value), max));
    },
    [max]
  );

  const changeValue = useCallback(
    (inc: number) => {
      setValue((prevValue) => {
        const newValue = prevValue + inc;
        // return newValue;
        return limitValue(newValue, max);
      });
    },
    [max]
  );

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange(Number(value));
    }
  }, [value]);

  useEffect(() => {
    if (!reset) {
      return;
    }
    setValue(initialValue);
  }, [reset]);

  return (
    <div className={classes.container}>
      <p className="text-center text-complementary">
        Entrez les mèters consommés
      </p>
      <div>
        <input type="number" onChange={handleOnChange} value={value} />
        <div>
          <button onClick={changeValue.bind(this, 1)} />
          <button onClick={changeValue.bind(this, -1)} />
        </div>
      </div>
      <p className="text-center text-complementary">mètres</p>
    </div>
  );
};
