import { Routes, Route } from "react-router-dom";
import { Consummation } from "./consummation/consummation.component";
import { Terminate } from "./terminate/terminate.component";
import { Detail } from "./detail/detail.component";
import { Content } from "components/layout/content/content.component";

function Navigation() {
  return (
    <Routes>
      <Route path="/" element={<Content />}>
        <Route index element={<Detail />} />
        <Route path=":deviceNumber" element={<Detail />} />
        <Route path="consummation/:deviceNumber" element={<Consummation />} />
        <Route path="terminate/:deviceNumber" element={<Terminate />} />
      </Route>
      <Route
        path="*"
        element={
          <main style={{ padding: "1rem" }}>
            <p>There's nothing here!</p>
          </main>
        }
      />
    </Routes>
  );
}

export default Navigation;
