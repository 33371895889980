import { useCallback, useState, useMemo } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useGetCoil } from "hooks/useGetCoil";
import { Button } from "components/common/button/button.component";
import { MetersConsumer } from "components/common/metersConsumer/metersConsumer.component";
import { ItemInfo } from "components/common/itemInfo/itemInfo.component";
import { Title } from "components/common/title/title.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { useAddConsumption } from "hooks/useAddConsumption";
import classes from "./consummation.module.sass";

export const Consummation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { addConsumption, isLoading } = useAddConsumption();
  const { selectedCoil } = useGetCoil();
  const [resetInput, setResetInput] = useState(false);
  const [metersAboutToConsume, setMetersAboutToConsume] = useState<number>(0);

  const metersAfterConsume = useMemo(() => {
    if (!selectedCoil) {
      return 0;
    }
    return selectedCoil.runningMeters - metersAboutToConsume;
  }, [selectedCoil, metersAboutToConsume]);

  const handleOnClickBtn = useCallback(async () => {
    if (!selectedCoil) {
      return null;
    }
    setResetInput(true);
    await addConsumption({
      id: selectedCoil.deviceNumber,
      metersConsumed: metersAboutToConsume,
    });
    navigate(`/${selectedCoil.deviceNumber}`);
  }, [selectedCoil, metersAboutToConsume]);

  if (!selectedCoil) {
    return <Navigate to={`/${params.deviceNumber || ""}`} replace={true} />;
  }

  return (
    <>
      <Title label="Ajouter consommation" />
      <div className={classes.centerContent}>
        <ItemInfo
          label="Mètres courants"
          data={`${selectedCoil.runningMeters}m`}
        />
      </div>
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <div>
            <MetersConsumer
              reset={resetInput}
              max={selectedCoil.runningMeters}
              onChange={(meters: number) => {
                setMetersAboutToConsume(meters);
              }}
            />
          </div>
          <p className={classes.textConfirmation}>
            Mètres restants après l'opération:{" "}
            <span className={classes.data}>{metersAfterConsume}m</span>
          </p>
          <Button
            onClick={!isLoading ? handleOnClickBtn : undefined}
            label="Confirmer"
            type="dark"
            shadow={true}
          />
        </>
      )}
    </>
  );
};
