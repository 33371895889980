import { BaseSyntheticEvent, useState, useCallback, useMemo } from "react";
import { Button } from "components/common/button/button.component";
import { ItemInfo } from "components/common/itemInfo/itemInfo.component";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useGetCoil } from "hooks/useGetCoil";
import { Title } from "components/common/title/title.component";
import { Spinner } from "components/common/spinner/spinner.component";
import { useCoilStatus } from "hooks/useCoilStatus";
import { useAddConsumption } from "hooks/useAddConsumption";
import classes from "./terminate.module.sass";

export const Terminate = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { selectedCoil } = useGetCoil();
  const { addConsumption, isLoading: isLoadingConsumption } =
    useAddConsumption();
  const [finished, setFinished] = useState<number>(() => {
    if (!selectedCoil) {
      return 0;
    }
    return selectedCoil.runningMeters > 0 ? 0 : 1;
  });
  const { updateCoilStatus, isLoading: isLoadingUpdateCoilStatus } =
    useCoilStatus();

  const isLoading = useMemo(() => {
    return isLoadingConsumption || isLoadingUpdateCoilStatus;
  }, [isLoadingConsumption, isLoadingUpdateCoilStatus]);

  const handleClickBtn = useCallback(async () => {
    if (!selectedCoil) {
      return;
    }
    await updateCoilStatus({
      id: selectedCoil.deviceNumber,
      finishedCoil: 1,
    });

    if (finished !== 0) {
      await addConsumption({
        id: selectedCoil.deviceNumber,
        metersConsumed: selectedCoil.runningMeters,
      });
    }
    navigate(`/${selectedCoil.deviceNumber}`);
  }, [selectedCoil, finished]);

  const handleOnClickRadio = (e: BaseSyntheticEvent) => {
    setFinished(Number(e.target.value));
  };

  const remainingMeters = useMemo(() => {
    if (!selectedCoil) {
      return;
    }
    return finished === 0 ? selectedCoil.runningMeters : 0;
  }, [finished]);

  if (!selectedCoil) {
    return <Navigate to={`/${params.deviceNumber || ""}`} replace={true} />;
  }

  return (
    <>
      <Title label="Bobine finie" />
      <div className={classes.infoWrapper}>
        <ItemInfo
          label="Mètres courants"
          data={`${selectedCoil.runningMeters}m`}
        />
        <ItemInfo
          label="Finished coil"
          data={selectedCoil.finishedCoil ? "Oui" : "No"}
          disabled
        />
      </div>
      {isLoading && (
        <div className="text-center">
          <Spinner />
        </div>
      )}
      {!isLoading && (
        <>
          <h3>Etat de la bobine</h3>
          <div className={classes.panelInfo}>
            <label className={classes.container}>
              <span className={classes.labelText}>Câble restant</span>
              <input
                name="etatBobine"
                type="radio"
                checked={finished === 0}
                value={0}
                onChange={handleOnClickRadio}
              />
              <span className={classes.checkmark}></span>
            </label>
            <div className={classes.dividerText}>Ou</div>
            <label className={classes.container}>
              <span className={classes.labelText}>Vide</span>
              <input
                name="etatBobine"
                type="radio"
                checked={finished === 1}
                value={1}
                onChange={handleOnClickRadio}
              />
              <span className={classes.checkmark}></span>
            </label>
          </div>
          <p className={classes.textConfirmation}>
            Mètres restants après l'opération:{" "}
            <span className={classes.data}>{remainingMeters}m</span>
          </p>
          <Button
            label="Confirmer"
            type="dark"
            shadow={true}
            onClick={isLoading ? undefined : handleClickBtn}
          />
        </>
      )}
    </>
  );
};
