import React, { createContext, useState } from "react";
import Coil from "constants/Coil";

interface ISelectedCoilContextProps {
  selectedCoil: Coil | null;
  setSelectedCoil: (selectedCoil: Coil | null) => void;
}

const SelectedCoilContext = createContext<ISelectedCoilContextProps>({
  selectedCoil: null,
  setSelectedCoil: () => {},
});

const SelectedCoilContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedCoil, setSelectedCoil] = useState<Coil | null>(null);

  return (
    <SelectedCoilContext.Provider value={{ selectedCoil, setSelectedCoil }}>
      {children}
    </SelectedCoilContext.Provider>
  );
};

export { SelectedCoilContext, SelectedCoilContextProvider };
