import { Link, useNavigate, useMatch } from "react-router-dom";
import { Button } from "components/common/button/button.component";
import { Icon, IconType } from "constants/icons";
import classes from "./header.module.sass";

interface IProps {
  location: string;
}

export const Header: React.FC<IProps> = ({ location }) => {
  const navigate = useNavigate();
  const match = useMatch("/:deviceNumber");

  return (
    <div className={classes.wrapper}>
      {match ? (
        <Link to="#">{Icon[IconType.LogoDTTS]}</Link>
      ) : (
        <button onClick={() => navigate(-1)}>
          <Button
            type="light"
            size="back-btn"
            label="Retourner"
            hideText={true}
            iconType={IconType.Arrow}
            shadow={true}
          />
        </button>
      )}
    </div>
  );
};
