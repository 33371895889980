import { BaseSyntheticEvent } from 'react';
import classes from './input.module.sass';

interface IPropsInput {
  type: string;
  onChange?: (value: string) => void
  placeholder?: string
  style?: object;
}

export const Input: React.FC<IPropsInput> = ({ type, onChange, placeholder, style }) => {

  const handleOnChange = (e: BaseSyntheticEvent) => {
    const value = e.currentTarget.value;
    if (typeof onChange === 'function') {
      onChange(value);
    }
  }

  return <input className={classes.container} type={type} onChange={handleOnChange} placeholder={placeholder} style={style} />
}