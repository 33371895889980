import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Footer } from "../footer/footer.component";
import { Header } from "../header/header.component";
import styles from "./content.module.sass";

export const Content: React.FunctionComponent = () => {
  const location = useLocation();

  React.useEffect(() => {
    console.log(location);
  }, [location]);

  return (
    <div className={styles.wrapper}>
      <Header location={location.pathname} />
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
