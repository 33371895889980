import { useState, useContext } from "react";
import { SelectedCoilContext } from "context/SelectedCoilContext";
import EP from "constants/EP";

const useGetCoil = () => {
  const { selectedCoil, setSelectedCoil } = useContext(SelectedCoilContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const getCoil = async (coilId?: string) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!coilId) {
        throw new Error("No Coil ID provided");
      }
      const response = await fetch(`${EP}coil?id=${coilId}`, {
        cache: "no-cache",
      });
      let coil = await response.json();
      if (typeof coil === "string") {
        throw new Error(`There is no coil with ID: ${coilId}`);
      }
      setSelectedCoil({
        ...coil,
        deviceNumber: coilId,
      });
      return coil;
    } catch (err: any) {
      setSelectedCoil(null);
      console.log(err);
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { getCoil, selectedCoil, error, isLoading };
};

export { useGetCoil };
