import { useState } from "react";
import EP from "constants/EP";

const useAddConsumption = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [runningMeters, setRunningMeters] = useState<number | null>(null);
  const [error, setError] = useState<null | string>(null);

  const addConsumption = async (consumptionData: {
    id: string;
    metersConsumed: number;
  }) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${EP}add-consumption`, {
        cache: "no-cache",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(consumptionData),
      });
      const consumption = await response.json();
      if (consumption.error) {
        throw new Error(consumption.error);
      }
      setRunningMeters(consumption.runningMeters);
    } catch (err: any) {
      console.log(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return { addConsumption, runningMeters, error, isLoading };
};

export { useAddConsumption };
