import { useState } from "react";
import EP from "constants/EP";

const useGetCoilId = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<null | string>(null);

  const getCoilId = async (coilReference?: string) => {
    setIsLoading(true);
    setError(null);
    try {
      if (!coilReference) {
        throw new Error("No Coil Reference provided");
      }
      const response = await fetch(`${EP}coilCodi?id=${coilReference}`, {
        cache: "no-cache",
      });
      let coil = await response.json();
      if (typeof coil === "string") {
        throw new Error(`There is no coil with reference: ${coilReference}`);
      }
      return coil;
    } catch (err: any) {
      console.log(err);
      setError(err.message);
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  return { getCoilId, error, isLoading };
};

export { useGetCoilId };
